@font-face {
font-family: '__GTAmerica_a9f72b';
src: url(/_next/static/media/18769048e86cfa05-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__GTAmerica_a9f72b';
src: url(/_next/static/media/bd2108e21b27b232-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__GTAmerica_a9f72b';
src: url(/_next/static/media/7e984032b7ed3bc0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__GTAmerica_a9f72b';
src: url(/_next/static/media/c4f9e9c6221fca37-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__GTAmerica_Fallback_a9f72b';src: local("Arial");ascent-override: 98.28%;descent-override: 25.36%;line-gap-override: 0.00%;size-adjust: 101.75%
}.__className_a9f72b {font-family: '__GTAmerica_a9f72b', '__GTAmerica_Fallback_a9f72b', helvetica, arial, sans-serif
}

